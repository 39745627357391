<template>
  <div class="bhomeMind">
    <div class="bhomeBody">
      <div class="bhomeBodyTop">
        <div class="bhomeBodyTopLeft">
          <img src="../../assets/you.png" class="bhomeBodyTopImg" />
          <div class="bhomeBodyTopLeftText">网站管理</div>
        </div>
        <div class="bhomeBodyTopLeft">
          <img src="../../assets/youactiv.png" class="bhomeBodyTopImg" />
          <div class="bhomeBodyTopText">网站首页</div>
        </div>
      </div>
      <div class="bhomeBodyTable">
        <el-form label-position="right" label-width="100px">
          <div class="bhomeBodyTableRow">
            <!-- 顶部轮播图： -->
            <el-form-item :label="titleTop">
              <el-button type="primary" @click="TopLunClick">更换</el-button>
              <el-button @click="TopLunSee">查看</el-button>
            </el-form-item>
            <!-- 产品中心： -->
            <el-form-item :label="titleOne">
              <el-button type="primary" @click="ProductClick">更换</el-button>
              <el-button @click="ProductSee">查看</el-button>
            </el-form-item>
          </div>
          <div class="bhomeBodyTableRow">
            <!-- 解决方案： -->
            <el-form-item :label="titleTow">
              <el-button type="primary" @click="SolutionClick">更换</el-button>
              <el-button @click="SolutionSee">查看</el-button>
            </el-form-item>
            <!-- 五大优势： -->
            <el-form-item :label="titleThree">
              <el-button type="primary" @click="advantageClick">更换</el-button>
              <el-button @click="advantageSee">查看</el-button>
            </el-form-item>
          </div>
          <div class="bhomeBodyTableRow">
            <!-- 企业介绍： -->
            <el-form-item :label="titleFour">
              <el-button type="primary" @click="introduceClick">更换</el-button>
              <el-button @click="introduceSee">查看</el-button>
            </el-form-item>
            <!-- 服务客户 -->
            <el-form-item :label="titleFiv">
              <el-button type="primary" @click="serviceClick">更换</el-button>
              <el-button @click="serviceSee">查看</el-button>
            </el-form-item>
          </div>
          <div class="bhomeBodyTableRow">
            <!-- 关于我们： -->
            <el-form-item :label="titleSix">
              <el-button type="primary" @click="aboutClick">更换</el-button>
              <el-button @click="aboutSee">查看</el-button>
            </el-form-item>
            <!-- 底部介绍 -->
            <el-form-item :label="titleSeven">
              <el-button type="primary" @click="botInforClick">更换</el-button>
              <el-button @click="botInforSee">查看</el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>

    <el-dialog v-model="dialogFormVisible"  @closed="closedSolutionClick" :title="title">
      <!-- 顶部轮播图 -->
      <el-form :model="form" v-if="titleIndex == 0">
        <el-form-item label="图片替换：" :label-width="formLabelWidth">
          <el-upload
            v-model:file-list="fileList"
            action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
            list-type="picture-card"
          >
            <el-icon><Plus /></el-icon>
          </el-upload>
        </el-form-item>
      </el-form>
      <!-- 产品中心： -->
      <el-form :model="form" v-else-if="titleIndex == 1">
        <el-form-item label="英文标题：" :label-width="formLabelWidth">
          <el-input style="width: 220px" />
        </el-form-item>
        <el-form-item label="中文标题：" :label-width="formLabelWidth">
          <el-input style="width: 220px" />
        </el-form-item>
        <el-form-item label="正反图一：" :label-width="formLabelWidth">
          <el-upload
            action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
            list-type="picture-card"
            :show-file-list="false"
          >
            <el-icon><Plus /></el-icon>
          </el-upload>
          <div class="komng"></div>
          <el-upload
            action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
            list-type="picture-card"
            :show-file-list="false"
          >
            <el-icon><Plus /></el-icon>
          </el-upload>
        </el-form-item>
        <el-form-item label="正反图二：" :label-width="formLabelWidth">
          <el-upload
            :show-file-list="false"
            action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
            list-type="picture-card"
          >
            <el-icon><Plus /></el-icon>
          </el-upload>
          <div class="komng"></div>
          <el-upload
            action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
            list-type="picture-card"
            :show-file-list="false"
          >
            <el-icon><Plus /></el-icon>
          </el-upload>
        </el-form-item>
        <el-form-item label="正反图三：" :label-width="formLabelWidth">
          <el-upload
            :show-file-list="false"
            action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
            list-type="picture-card"
          >
            <el-icon><Plus /></el-icon>
          </el-upload>
          <div class="komng"></div>
          <el-upload
            action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
            list-type="picture-card"
            :show-file-list="false"
          >
            <el-icon><Plus /></el-icon>
          </el-upload>
        </el-form-item>
      </el-form>
      <!-- 解决方案： -->
      <el-form
        :model="formSolution"
        ref="ruleSolutionRef"
        :rules="solutionRules"
        v-else-if="titleIndex == 2"
      >
        <el-form-item
          label="英文标题："
          prop="englishTitle"
          :label-width="formLabelWidth"
        >
          <el-input style="width: 220px" v-model="formSolution.englishTitle" />
        </el-form-item>
        <el-form-item
          label="中文标题："
          prop="chineseTitle"
          :label-width="formLabelWidth"
        >
          <el-input style="width: 220px" v-model="formSolution.chineseTitle" />
        </el-form-item>
        <el-form-item label="深度定制：" :label-width="formLabelWidth">
          <el-table
            :data="tableData"
            :row-key="id"
            @row-click="filterChange"
            style="width: 100%"
          >
            <el-table-column label="icon图" width="180">
              <template #default="scope">
                <el-upload
                  class="upload-demo"
                  ref="uploadLicense"
                  name="file"
                  :action="action"
                  :headers="headers"
                  :show-file-list="false"
                  :on-change="onChangeonen"
                  :on-success="onSuccessLicense"
                >
                  <img
                    v-if="scope.row.iconPic"
                    class="sfzImg"
                    :src="scope.row.iconPic"
                  />
                  <div v-else class="sfzImg">推荐44*44</div>
                </el-upload>
              </template>
            </el-table-column>
            <el-table-column label="标题" width="180">
              <template #default="scope">
                <el-input
                  v-model="scope.row.title"
                  class="w-50 m-2"
                  size="large"
                  placeholder="请输入"
                />
              </template>
            </el-table-column>
            <el-table-column prop="address" label="内容">
              <template #default="scope">
                <el-input
                  v-model="scope.row.content"
                  class="textareaClass"
                  size="large"
                  :rows="4"
                  type="textarea"
                  placeholder="请输入"
                />
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <!-- 五大优势 -->
      <el-form :model="form" v-else-if="titleIndex == 3">
        <el-form-item label="英文标题：" :label-width="formLabelWidth">
          <el-input style="width: 220px" />
        </el-form-item>
        <el-form-item label="中文标题：" :label-width="formLabelWidth">
          <el-input style="width: 220px" />
        </el-form-item>
        <el-form-item label="图片替换：" :label-width="formLabelWidth">
          <el-upload
            :action="action"
            list-type="picture-card"
            :show-file-list="false"
          >
            <el-icon><Plus /></el-icon>
          </el-upload>
        </el-form-item>
        <el-form-item label="第一点：" :label-width="formLabelWidth">
          <el-input style="width: 220px" />
        </el-form-item>
        <el-form-item label="第二点：" :label-width="formLabelWidth">
          <el-input style="width: 220px" />
        </el-form-item>
        <el-form-item label="第三点：" :label-width="formLabelWidth">
          <el-input style="width: 220px" />
        </el-form-item>
        <el-form-item label="第四点：" :label-width="formLabelWidth">
          <el-input style="width: 220px" />
        </el-form-item>
        <el-form-item label="第五点：" :label-width="formLabelWidth">
          <el-input style="width: 220px" />
        </el-form-item>
      </el-form>
      <!-- 企业介绍： -->
      <el-form :model="form" v-else-if="titleIndex == 4">
        <el-form-item label="英文标题：" :label-width="formLabelWidth">
          <el-input style="width: 220px" />
        </el-form-item>
        <el-form-item label="中文标题：" :label-width="formLabelWidth">
          <el-input style="width: 220px" />
        </el-form-item>
        <el-form-item label="图片替换：" :label-width="formLabelWidth">
          <el-upload
            :action="action"
            list-type="picture-card"
            :show-file-list="false"
          >
            <el-icon><Plus /></el-icon>
          </el-upload>
        </el-form-item>
        <el-form-item label="内容：" :label-width="formLabelWidth">
          <el-input style="width: 220px" :rows="8" type="textarea" />
        </el-form-item>
      </el-form>
      <!-- 服务客户 -->
      <el-form :model="form" v-else-if="titleIndex == 5">
        <el-form-item label="英文标题：" :label-width="formLabelWidth">
          <el-input style="width: 220px" />
        </el-form-item>
        <el-form-item label="中文标题：" :label-width="formLabelWidth">
          <el-input style="width: 220px" />
        </el-form-item>
        <el-form-item label="标题介绍：" :label-width="formLabelWidth">
          <el-input style="width: 220px" />
        </el-form-item>
        <el-form-item label="服务介绍：" :label-width="formLabelWidth">
          <el-table :data="tableServData" style="width: 100%">
            <el-table-column label="图片" width="300">
              <template #default="scope">
                <el-upload
                  class="upload-demo"
                  ref="uploadLicense"
                  name="pic"
                  action="https://dbsxjn.cn:8084/business/auth/cosImg"
                  :show-file-list="false"
                  :on-change="onChangeonen"
                  :on-success="onSuccessLicense"
                >
                  <img
                    v-if="scope.row.date"
                    class="sfzImg"
                    :src="scope.row.date"
                  />
                  <div v-else class="sfzImg">推荐280*200</div>
                </el-upload>
              </template>
            </el-table-column>
            <el-table-column label="标题" width="300">
              <template #default="scope">
                <el-input
                  v-model="scope.row.title"
                  class="w-50 m-2"
                  size="large"
                  placeholder="请输入"
                />
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <!-- 关于我们： -->
      <el-form :model="form" v-else-if="titleIndex == 6">
        <el-form-item label="服务介绍：" :label-width="formLabelWidth">
          <el-table :data="tableAboutData" style="width: 100%">
            <el-table-column label="icon图片" width="300">
              <template #default="scope">
                <el-upload
                  class="upload-demo"
                  ref="uploadLicense"
                  name="pic"
                  action="https://dbsxjn.cn:8084/business/auth/cosImg"
                  :show-file-list="false"
                  :on-change="onChangeonen"
                  :on-success="onSuccessLicense"
                >
                  <img
                    v-if="scope.row.date"
                    class="sfzImg"
                    :src="scope.row.date"
                  />
                  <div v-else class="sfzImg">推荐80*80</div>
                </el-upload>
              </template>
            </el-table-column>
            <el-table-column label="标题" width="300">
              <template #default="scope">
                <el-input
                  v-model="scope.row.title"
                  class="w-50 m-2"
                  size="large"
                  placeholder="请输入"
                />
              </template>
            </el-table-column>
            <el-table-column prop="address" label="内容">
              <template #default="scope">
                <el-input
                  v-model="scope.row.value"
                  class="textareaClass"
                  size="large"
                  :rows="4"
                  type="textarea"
                  placeholder="请输入"
                />
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <!-- 底部介绍 -->
      <el-form :model="form" v-else>
        <el-form-item label="热线电话：" :label-width="formLabelWidth">
          <el-input style="width: 220px" />
        </el-form-item>
        <el-form-item label="电子邮箱：" :label-width="formLabelWidth">
          <el-input style="width: 220px" />
        </el-form-item>
        <el-form-item label="公司地址：" :label-width="formLabelWidth">
          <el-input style="width: 220px" />
        </el-form-item>
        <el-form-item label="微信二维码：" :label-width="formLabelWidth">
          <el-upload
            action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
            list-type="picture-card"
            :show-file-list="false"
          >
            <el-icon><Plus /></el-icon>
          </el-upload>
        </el-form-item>
      </el-form>
      <template #footer v-if="showBtn">
        <span class="dialog-footer">
          <el-button @click="CancelClick">取消</el-button>
          <el-button type="primary" @click="ConfirmClick">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      action: "http://192.168.0.126:8080/file",
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("token"),
      },
      dialogFormVisible: false,
      formLabelWidth: "140px",
      title: "",
      titleTop: "顶部轮播图：",
      titleOne: "产品中心：",
      titleTow: "解决方案：",
      titleThree: "五大优势：",
      titleFour: "企业介绍：",
      titleFiv: "服务客户",
      titleSix: "关于我们：",
      titleSeven: "底部介绍：",
      showBtn: true,
      titleIndex: "", //0：顶部轮播图，1：产品中心，2：解决方案，
      //3：五大优势，4：企业介绍，5：服务客户，6:关于我们,7:底部介绍
      tableData: [
        {
          id: 0,
          iconPic: "",
          title: "",
          content: "",
        },
        {
          id: 1,
          iconPic: "",
          title: "",
          content: "",
        },
        {
          id: 2,
          iconPic: "",
          title: "",
          content: "",
        },
        {
          id: 3,
          iconPic: "",
          title: "",
          content: "",
        },
      ],
      tableServData: [
        {
          id: 0,
          date: "",
          title: "",
        },
        {
          id: 1,
          date: "",
          title: "",
        },
        {
          id: 2,
          date: "",
          title: "",
        },
        {
          id: 3,
          date: "",
          title: "",
        },
        {
          id: 4,
          date: "",
          title: "",
        },
        {
          id: 5,
          date: "",
          title: "",
        },
        {
          id: 6,
          date: "",
          title: "",
        },
        {
          id: 7,
          date: "",
          title: "",
        },
      ],
      tableAboutData: [
        {
          id: 0,
          date: "",
          title: "",
          value: "",
        },
        {
          id: 1,
          date: "",
          title: "",
          value: "",
        },
        {
          id: 2,
          date: "",
          title: "",
          value: "",
        },
        {
          id: 3,
          date: "",
          title: "",
          value: "",
        },
      ],
      formSolution: {
        //解决方案表单数据
        chineseTitle: "",
        englishTitle: "",
        inDepthCustomization: [],
      },
      solutionRules: {
        englishTitle: [{ required: true, message: "请输入", trigger: "blur" }],
        chineseTitle: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      idIndex: "",
    };
  },
  created() {},
  methods: {
    // 顶部轮播图
    TopLunClick() {
      this.dialogFormVisible = true;
      this.title = this.titleTop;
      this.showBtn = true;
      this.titleIndex = 0;
    },
    TopLunSee() {
      this.dialogFormVisible = true;
      this.title = this.titleTop;
      this.showBtn = false;
      this.titleIndex = 0;
    },
    // 产品中心：
    ProductClick() {
      this.dialogFormVisible = true;
      this.title = this.titleOne;
      this.showBtn = true;
      this.titleIndex = 1;
    },
    ProductSee() {
      this.dialogFormVisible = true;
      this.title = this.titleOne;
      this.showBtn = false;
      this.titleIndex = 1;
    },
    // 解决方案：
    SolutionClick() {
      this.dialogFormVisible = true;
      this.title = this.titleTow;
      this.showBtn = true;
      this.titleIndex = 2;
    },
    SolutionSee() {
      this.dialogFormVisible = true;
      this.title = this.titleTow;
      this.showBtn = false;
      this.titleIndex = 2;
    },
    // 五大优势
    advantageClick() {
      this.dialogFormVisible = true;
      this.title = this.titleThree;
      this.showBtn = true;
      this.titleIndex = 3;
    },
    advantageSee() {
      this.dialogFormVisible = true;
      this.title = this.titleThree;
      this.showBtn = false;
      this.titleIndex = 3;
    },
    // 企业介绍：
    introduceClick() {
      this.dialogFormVisible = true;
      this.title = this.titleFour;
      this.showBtn = true;
      this.titleIndex = 4;
    },
    introduceSee() {
      this.dialogFormVisible = true;
      this.title = this.titleFour;
      this.showBtn = false;
      this.titleIndex = 4;
    },
    // 服务客户
    serviceClick() {
      this.dialogFormVisible = true;
      this.title = this.titleFiv;
      this.showBtn = true;
      this.titleIndex = 5;
    },
    serviceSee() {
      this.dialogFormVisible = true;
      this.title = this.titleFiv;
      this.showBtn = false;
      this.titleIndex = 5;
    },
    // 关于我们：
    aboutClick() {
      this.dialogFormVisible = true;
      this.title = this.titleSix;
      this.showBtn = true;
      this.titleIndex = 6;
    },
    aboutSee() {
      this.dialogFormVisible = true;
      this.title = this.titleSix;
      this.showBtn = false;
      this.titleIndex = 6;
    },
    // 底部介绍
    botInforClick() {
      this.dialogFormVisible = true;
      this.title = this.titleSeven;
      this.showBtn = true;
      this.titleIndex = 7;
    },
    botInforSee() {
      this.dialogFormVisible = true;
      this.title = this.titleSeven;
      this.showBtn = false;
      this.titleIndex = 7;
    },
    // 取消弹框
    CancelClick() {
      this.dialogFormVisible = false;
      this.title = "";
    },
    // 确认弹框
    ConfirmClick() {
      // 解决方案
      if (this.titleIndex == 2) {
        this.$refs.ruleSolutionRef.validate((res) => {
          if (res) {
            console.log(this.tableData);
            this.tableData.forEach((i) => {
              if (
                this.$utils.isEmpty(i.iconPic) ||
                this.$utils.isEmpty(i.title) ||
                this.$utils.isEmpty(i.content)
              ) {
                this.$message({
                  message: "请填写完整",
                  type: "error", //info , error ,success
                  center: true,
                });
                this.formSolution.inDepthCustomization = [];
                return;
              } else {
                this.formSolution.inDepthCustomization = this.tableData
              }
            });
            this.$api.api(
              "firstSolution/add",
              this.formSolution,
              "post",
              (res) => {
                console.log("解决方案确认回调", res);
                if (res.data.code == 200) {
                  this.dialogFormVisible = false;
                  this.$message({
                    message: res.data.msg,
                    type: "success", //info , error ,success
                    center: true,
                  });
                }
              }
            );
          }
        });
      }
    },
    // 弹框关闭
    closedSolutionClick() {
      this.$refs.ruleSolutionRef.resetFields();
      this.formSolution ={
        englishTitle:'',
        chineseTitle:'',
        inDepthCustomization:[]
      }
      this.idIndex = ''
    },
    // 表格点击行
    filterChange(res) {
      let a = JSON.stringify(res);
      let b = JSON.parse(a);
      console.log("表格当前航", b);
      this.idIndex = b.id;
    },
    onChangeonen(files, fileList) {
      console.log("上传图片", files);
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
    },
    onSuccessLicense(files) {
      console.log("上传图片回调", files);
      this.tableData[this.idIndex].iconPic = files.data.url;
      // this.updisabled = false
    },
  },
};
</script>

<style>
.bhomeMind {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bhomeBody {
  width: 96%;
  height: 94%;
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
}

.bhomeBodyTop {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e2e2;
  font-size: 18px;
}

.bhomeBodyTopLeft {
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.bhomeBodyTopImg {
  width: 10px;
  height: 10px;
  margin-right: 8px;
}

.bhomeBodyTopLeftText {
  color: #0a0a0a;
}

.bhomeBodyTopText {
  color: #278be0;
}

.bhomeBodyTable {
  width: 100%;
  height: calc(100% - 40px - 41px);
  padding: 40px 10%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.bhomeBodyTableRow {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
}

.bhomeBodyTableRowItem {
  width: 50%;
  height: 80px;
  display: flex;
  align-items: center;
}

.komng {
  width: 80px;
}

.sfzImg {
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  border: 1px dashed silver;
}

.textareaClass {
  width: 100%;
  height: 100px;
}
</style>